import ArrowNarrowRightIcon from '@/components/icons/arrow-narrow-right';
import CheckCircleIcon from '@/components/icons/check-circle';
import Loading01Icon from '@/components/icons/loading-01';
import XCircleIcon from '@/components/icons/x-circle';
import { clsx } from 'clsx';

export default function SubmitButton({
	isLoading,
	mini = false,
	isSubmitted = false,
	isErrored,
	disabled = false,
	ariaLabel,
	text,
	icon,
	className = '',
	form = undefined,
}: {
	isLoading?: boolean;
	isSubmitted?: boolean;
	mini?: boolean;
	isErrored?: boolean;
	disabled?: boolean;
	ariaLabel?: string;
	text?: string;
	className?: string;
	form?: string;
	icon?: React.ReactNode;
}) {
	return (
		<button
			disabled={isLoading || isSubmitted || disabled}
			className={clsx(
				'inline-flex rounded-2xl border-0 text-center text-base font-medium leading-6 outline-offset-2',
				className,
				{
					'bg-dodger-blue-500 hover:bg-dodger-blue-600': !isSubmitted && !isLoading && !disabled,
					'text-white': !disabled,
					'bg-valid-green': isSubmitted && !isLoading && !isErrored,
					'bg-error-red': isSubmitted && !isLoading && isErrored,
					'bg-grey-light': isLoading,
					'flex h-12 max-h-12 w-12 items-center justify-center p-0': !text,
					'items-center justify-center px-5 py-3.5': text && !mini,
					'items-center justify-center px-[1.125rem] py-[0.6875rem]': text && mini,
					'gap-[10px]': icon,
					'bg-grey-light text-grey-dark': disabled,
				},
			)}
			type="submit"
			aria-label={ariaLabel}
			form={form}
		>
			{!isSubmitted && text && text}
			{!isSubmitted && !isLoading && icon && icon}
			{!isSubmitted && !isLoading && !text && <ArrowNarrowRightIcon width={24} height={24} />}
			{isLoading && !isSubmitted && (
				<Loading01Icon className="animate-spin" width={24} height={24} />
			)}
			{isSubmitted && !isLoading && !isErrored && <CheckCircleIcon width={24} height={24} />}
			{isSubmitted && !isLoading && isErrored && <XCircleIcon width={24} height={24} />}
		</button>
	);
}
