/* eslint-disable @typescript-eslint/no-explicit-any */
import { StripePlanEnum } from '@/models/stripe-plan.enum';
import { sendGTMEvent } from '@next/third-parties/google';

function trackEvent(event: string, args: Record<string, any>) {
	if (typeof window.dataLayer !== 'undefined') {
		sendGTMEvent({ event, ...args });
	} else {
		console.info({
			event,
			...args,
		});
	}
}

export const trackSignUp = (method: string) => {
	trackEvent('sign_up', { method });
};

export const trackLogin = (method: string) => {
	trackEvent('login', { method });
};

export const trackPurchase = (plan: Exclude<StripePlanEnum, StripePlanEnum.free>) => {
	trackEvent('purchase', {
		currency: 'EUR',
		value: 1,
		items: [
			{
				item_id: plan,
				price: 1,
			},
		],
	});
};

export const trackBeginCheckout = (
	plan: Exclude<StripePlanEnum, StripePlanEnum.free>,
	billing: 'yearly' | 'monthly',
) => {
	trackEvent('begin_checkout', {
		currency: 'EUR',
		value: billing === 'yearly' ? 99.99 : 9.99,
		items: [
			{
				item_id: plan,
				price: billing === 'yearly' ? 99.99 : 9.99,
			},
		],
	});
};
