import EyeIcon from '@/components/icons/eye';
import EyeOffIcon from '@/components/icons/eye.off';
import { ReactInputProps } from '@/models/components-props/Input';
import { clsx } from 'clsx';
import { useId, useState } from 'react';
import { FieldError, Merge, UseFormRegisterReturn } from 'react-hook-form';

type LokaInputProps = ReactInputProps & {
	error?: FieldError | Merge<FieldError, (FieldError | undefined)[]> | undefined;
	registered?: UseFormRegisterReturn;
	floatingLabel?: boolean;
	label: string;
	suffix?: React.ReactNode;
	type: 'text' | 'password' | 'email' | 'number' | 'tel' | 'date' | 'month';
	className?: string;
};

export default function Input({
	error,
	registered,
	label,
	floatingLabel,
	suffix,
	type: baseType,
	className,
	readOnly,
	required,
	...props
}: LokaInputProps) {
	const id = useId();
	const [type, setType] = useState(baseType);
	const [showPassword, setShowPassword] = useState(false);

	const clickShowPassword = () => {
		setShowPassword(!showPassword);
		setType(showPassword ? 'password' : 'text');
	};

	return (
		<div
			className={clsx('relative', className, {
				'opacity-50': props.disabled,
			})}
		>
			<input
				placeholder={floatingLabel ? ' ' : label}
				id={id}
				className={clsx(
					'col-start-1 w-full rounded-xl border border-input-border bg-opacity-50 p-4 text-base leading-6 text-black-text outline-offset-2 transition-colors duration-200 ease-in-out focus:outline-none disabled:border-grey-dark disabled:bg-white disabled:text-grey-dark',
					{
						peer: floatingLabel,
						'border-red-500 focus:border-red-500 focus:ring-red-200': error && !readOnly,
						'focus:border-dodger-blue-500 focus:ring-2 focus:ring-dodger-blue-200':
							!error && !readOnly,
						'border-grey-dark text-grey-dark': readOnly,
					},
				)}
				type={type}
				readOnly={readOnly}
				required={required}
				{...props}
				{...(registered ?? {})}
			/>
			{floatingLabel && (
				<label
					htmlFor={id}
					className={clsx(
						'absolute left-1 top-2 z-10 origin-[0] -translate-y-[17.6px] translate-x-2 scale-75 transform bg-gradient-to-b from-transparent from-[36%] via-transparent to-white to-[36%] px-2 text-base font-medium text-black-text transition-all duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:font-light peer-focus:top-[6.2px] peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:from-[23%] peer-focus:to-[23%] peer-focus:px-2 peer-focus:font-medium peer-focus:text-blue-600',
						{
							'text-red-500 peer-focus:text-red-500': error && !readOnly,
							'text-grey-dark': readOnly,
						},
					)}
				>
					{required ? `${label} *` : label}
				</label>
			)}
			{baseType === 'password' && (
				<button
					className="absolute right-4 top-4 h-6 w-6"
					type="button"
					onClick={() => {
						clickShowPassword();
					}}
					aria-label={!showPassword ? 'Afficher le mot de passe' : 'Cacher le mot de passe'}
				>
					{!showPassword && <EyeIcon className="top-0 text-black-text" />}
					{showPassword && <EyeOffIcon className="top-0 text-black-text" />}
				</button>
			)}
			{suffix && (
				<span
					className={clsx('absolute bottom-0 right-4 top-0 flex items-center justify-center', {
						'text-grey-dark': (!error && floatingLabel) || readOnly,
						'text-error-red': error && !readOnly,
						'peer-focus:text-black-text peer-[&:not(:placeholder-shown)]:text-black-text':
							floatingLabel && !error && !readOnly,
						'text-black-text': !floatingLabel && !error && !readOnly,
					})}
				>
					{suffix}
				</span>
			)}
		</div>
	);
}
